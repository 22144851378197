<template>
  <section class="dashboard" ref="data_section">
    <div style="margin-top: 40px;"></div>
    <div v-for="(item, index) in userData" class="d-row">
      <el-row>
        <el-col :span="24" class="d-title">
          <span>{{item.name}}</span>
        </el-col>
      </el-row>
      <el-row class="d-menu-row">
        <el-col v-for="(c, i) in item.children" :span="5" :offset="(i%4)>0?1:0" class="item">
          <img :src="c.imgSrc"  />
          <router-link  v-if="!c.url || c.url == ''" :to="c.path" :key="i">
            <span>{{c.name}}</span>
          </router-link>
          <a v-else target="_blank" :href="c.url">
            <span>{{c.name}}</span>
          </a>
        </el-col>
      </el-row>
    </div>



  </section>
</template>

<script>

  import {mapActions, mapGetters, mapState} from 'vuex'
  import util from '@/common/util'
  import * as simpleApi from "request/simple";
  import XEUtils from "xe-utils";

  export default {
    data(){
      return {
        userData: [],
        data: [{
          name: "基础信息管理",  allows: ['admin'],
          children: [
            {
              name: "二级单位信息", imgSrc: require("assets/dashboard/base_info.jpeg"), path: "/mainPage/schoolList", allows: ['admin'],
            }, {
              name: "二级单位管理员", imgSrc: require("assets/dashboard/admin.jpeg"), path: "/mainPage/admin2List", allows: ['admin'],
            }, {
              name: "专业管理", imgSrc: require("assets/dashboard/professional.jpeg"), path: "/mainPage/professionList", allows: ['admin'],
            }
          ]
        }, {
        }, {
          name: "用户管理",  allows: ['admin'],
          children: [{
            name: "学生信息", imgSrc: require("assets/dashboard/student.jpeg"), path: "/mainPage/studentList", allows: ['admin'],
          }, {
            name: "教师信息", imgSrc: require("assets/dashboard/teacher.jpeg"), path: "/mainPage/teacherList", allows: ['admin'],
          }, {
            name: "行政班信息", imgSrc: require("assets/dashboard/my_class.jpeg"), path: "/mainPage/hardClassList", allows: ['admin'],
          }, {
            name: "课程信息", imgSrc: require("assets/dashboard/course.jpeg"), path: "/mainPage/courceList", allows: ['admin'],
          }, {
            name: "教学班信息", imgSrc: require("assets/dashboard/teaching_class.jpeg"), path: "/mainPage/teachingClassList", allows: ['admin'],
          }, {
            name: "综合上传", imgSrc: require("assets/dashboard/upload.jpg"), path: "/mainPage/allUpload", allows: ['admin'],
          }]
          // 单位管理员
        }, {
          name: "用户管理",  allows: ['sclAdmin'],
          children: [{
            name: "学生信息", imgSrc: require("assets/dashboard/student.jpeg"), path: "/mainPage/scl/studentList", allows: ['sclAdmin'],
          }, {
            name: "教师信息", imgSrc: require("assets/dashboard/teacher.jpeg"), path: "/mainPage/scl/teacherList", allows: ['sclAdmin'],
          }, {
            name: "行政班信息", imgSrc: require("assets/dashboard/my_class.jpeg"), path: "/mainPage/scl/hardClassList", allows: ['sclAdmin'],
          }, {
            name: "课程信息", imgSrc: require("assets/dashboard/course.jpeg"), path: "/mainPage/scl/courceList", allows: ['sclAdmin'],
          }, {
            name: "教学班信息", imgSrc: require("assets/dashboard/teaching_class.jpeg"), path: "/mainPage/scl/teachingClassList", allows: ['sclAdmin'],
          }, {
            name: "综合上传", imgSrc: require("assets/dashboard/upload.jpg"), path: "/mainPage/allUpload", allows: ['sclAdmin'],
          }]
          // 教师
        }, {
          name: "用户管理",  allows: ['teacher'],
          children: [{
            name: "课程信息", imgSrc: require("assets/dashboard/course.jpeg"), path: "/mainPage/tch/courceList", allows: ['teacher'],
          }, {
            name: "教学班信息", imgSrc: require("assets/dashboard/teaching_class.jpeg"), path: "/mainPage/tch/teachingClassList", allows: ['teacher'],
          }, {
            name: "我的学生", imgSrc: require("assets/dashboard/student.jpeg"), path: "/mainPage/tch/myStudentList", allows: ['teacher'],
          }]
          //教师 笔试考试
        }, {
          name: "笔试考试",  allows: ['teacher'],
          children: [{
            name: "新建考试", imgSrc: require("assets/dashboard/exam.jpg"),  url: "/exam/#/exam-manage/index", allows: ['teacher'],
          }, {
            name: "上传答题卡", imgSrc: require("assets/dashboard/answer_card.jpg"), url: "/exam/#/exam-manage/index", allows: ['teacher'],
          }, {
            name: "答案设置", imgSrc: require("assets/dashboard/answer.jpeg"), url: "/exam/#/exam-manage/index", allows: ['teacher'],
          }, {
            name: "阅卷任务分配", imgSrc: require("assets/dashboard/yuejuan_task.jpeg"), url: "/exam/#/exam-manage/index", allows: ['teacher'],
          }, {
            name: "阅卷", imgSrc: require("assets/dashboard/yuejuan.jpg"), url: "/exam/#/exam-manage/index", allows: ['teacher'],
          }]
        },{
          name: "答题卡",  allows: ['teacher'],
          children: [{
            name: "答题卡列表", imgSrc: require("assets/dashboard/answer_card.jpg"), url: "/scantron/#/list/index", allows: ['teacher'],

          }]
        }, {
          name: "扫描", allows: ['teacher'],
          children: [{
            name: "扫描答题卡", imgSrc: require("assets/dashboard/scan.jpg"), path: "/mainPage/cef/scan", allows: ['teacher'],
          }, {
            name: "待识别队列", imgSrc: require("assets/dashboard/shibie.jpeg"), path: "/mainPage/cef/ocring/list", allows: ['teacher'],
          }, {
            name: "异常卷处理", imgSrc: require("assets/dashboard/exception.jpeg"), path: "/mainPage/exceptPage", allows: ['teacher'],
          }]

        }, {
          name: "我的成绩", imgSrc: require("assets/dashboard/base_info.jpeg"), path: "/mainPage/myScoreList", allows: ['student'],

        }, {
          name: "成绩管理", allows: ['teacher'],
          children: [{
            name: "成绩单", imgSrc: require("assets/dashboard/score.jpeg"), path: "/mainPage/examsList", allows: ['teacher'],
          }, {
            name: "试卷分析", imgSrc: require("assets/dashboard/exam_ana.jpg"), path: "/mainPage/examsAnalyse", allows: ['teacher'],
          }, {
            name: "归档下载", imgSrc: require("assets/dashboard/download.jpeg"), path: "/mainPage/exceptPage", allows: ['teacher'],
          }]
          // }, {
          //     name: "达成度管理", imgSrc: require("el-icon-user", allows: ['teacher'],
          //     children: []
          // }, {
          //     name: "总评表管理", imgSrc: require("el-icon-user", allows: ['teacher'],
          //     children: []
        }],
      }
    },
    components: {

    },
    computed:{
      ...mapGetters('user', [
        'permission_routers',
        'isCollapse',
        'sidebar',
        'menuIndex'
      ]),
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
    },
    created(){
      this.loadUserMenu();
    },
    mounted(){
      this.loadPing();
    },
    methods: {

      loadUserMenu(){
        let role = this.userInfo.role;
        this.userData = [];
        for (let i=0; i<this.data.length; i++){
          let d = this.data[i];
          let allows = d.allows;
          let ar = XEUtils.find(allows, item => {
            return item == role;
          });
          if(ar){
            this.userData.push(d);
          }
        }
      },
      loadPing(){
        let param = { controllerName: 'ping', methodName: '/test'};
        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
          console.log("ping", code, data)
        }).catch(() => {

        });
      },
    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">
  .dashboard{
    padding: 50px 50px;
    .d-row {
      padding: 10px 10px;
      margin-top: 30px;
      background-color: white;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      .d-title{
        text-align: left;
        padding-left: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(150, 151, 153, 0.23);
        span{
          padding: 10px 10px;
          font-size: 18px;
          font-weight: bold;
        }
      }
      .d-menu-row {
        margin-top: 10px;
        .item{
          padding-left: 70px;
          text-align: left;
          float: left;
          margin-top: 15px;
        }
        img{
          width: 40px; height: 40px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          border-radius: 10px;
          vertical-align: middle;
        }
        span {
          margin-left: 20px;
        }
        a {
          color: black;
        }
      }
    }

  }

</style>
